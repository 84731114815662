// import only the Bootstrap modules you need, e.g.
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/forms";
// import your own custom files, e.g.

// importar tipografias
@font-face {
  font-family: "centrale_sans_bold";
  src: url("../assets/fonts/centrale_sans_bold.otf");
}

@font-face {
  font-family: "centrale_sans_light";
  src: url("../assets/fonts/centrale_sans_light.otf");
}

@font-face {
  font-family: "centrale_sans_medium";
  src: url("../assets/fonts/centrale_sans_medium.otf");
}

@font-face {
  font-family: "centrale-Sans-Light-Italic";
  src: url("../assets/fonts/centrale-Sans-Light-Italic.otf");
}

@font-face {
  font-family: "centrale_sans_bold";
  src: url("../assets/fonts/centrale_sans_bold.otf");
}

@font-face {
  font-family: "Stag-Book";
  src: url("../assets/fonts/Stag-Book.otf");
}

/* modal  */
$azul-principal: #043964;
$azul-principal-accent: #0961a8;

$color-fondos-modal: rgba(0, 0, 0, 0.6);
.backdrop {
  background-color: $color-fondos-modal;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.modal-title {
  text-align: center;
  width: 100%;
}

table {
  width: 100%;
  border: 1px solid #f1f1f1;
}

.modal-header {
  margin-bottom: 16px;
}

tr.mat-header-row {
  background-color: #f1f1f1;
}

::ng-deep .mat-sort-header-container {
  display: flex;
  justify-content: center;
}
.btn-aceptar {
  color: #ffffff;
  background-color: $azul-principal;
}
.btn-aceptar:hover {
  background-color: $azul-principal-accent;
}

.np {
  padding: 0 !important;
}
.filtrar {
  border-bottom: 1px solid;
  margin-bottom: 13px;
  width: 20%;
  color: #000000;
}
.input-tegnologia {
  padding-left: 26px;
}

hr {
  margin-top: 0rem;
}

.mat-sort-header-content {
  margin-left: auto;
  margin-right: auto;
}

th,
td {
  border: 1px solid #d6d2d2;
  color: #000000;
  font-family: "centrale_sans_medium";
  text-align: center;
}

#app_version {
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 99999;
}

.navbar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
}

.navbar a {
  float: left;
  color: #494949;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}
