// import more custom files...
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$RepmanWeb-primary: mat-palette($mat-indigo);
$RepmanWeb-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$RepmanWeb-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$RepmanWeb-theme: mat-light-theme((
  color: (
    primary: $RepmanWeb-primary,
    accent: $RepmanWeb-accent,
    warn: $RepmanWeb-warn,
  )
));

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
   margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-image: url(assets/images/general_view_background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

.rojo-error{
  color: #b00020;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
th,
td {
    color: #000000;
    font-family: "centrale_sans_medium";
}
